@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

body
{
  margin: 0;
  font-weight: 300;
  line-height: 1.7;
  overflow-x: hidden;
  font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

:root
{

  --font-color-header: white;
  --bg-color--header: #242526;
  --text-secondary: #b0b3b8;

  --color-main-content : white;
  --bg-color-main-content : #18191a;

  --color-footer : white;
  --bg-color-footer : #18191a;

  --primary-color: #1A45C2;
  --success-color: #12a366;

  --toastify-color-success: var(--primary-color);

  [theme='light']
  {
    --font-color-header: red;
    --bg-color--header: red;
    --text-secondary: #red;
  
    --color-main-content : red;
    --bg-color-main-content : #red;
  
    --color-footer : red;
    --bg-color-footer : red;
  }
}

.maiar-shadow:focus,
.maiar-shadow:focus,
button:focus,
button:active,
a:focus,
a:active
{
  box-shadow: 0 0px 10px 0 var(--primary-color) !important;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

a:-webkit-any-link:focus-visible,
button:focus,
button:active
{
  outline: none;
}
