.spinning {
    animation: spin 2s infinite linear;
}

@keyframes spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(720deg);
}
}
