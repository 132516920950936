.border-card {
    border: 1px solid #4e5052;
}

.bg-card {
    background-color: var(--bg-color--header);
}

.custom-card {
    background-color: var(--bg-color--header);
    width: min(100%, 500px);	
    box-shadow: 0px 0px 30px 0px rgba(36,37,38, 1);
    place-self: center;

}

.card-header {
    border-bottom: 1px solid #4e5052;
    background-color: inherit;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    img {
        width: 4em;
        height: auto;
        border-radius: 100%;
        border: 1px solid #4e5052;
        padding: .2em;
    }

}

.card-title {
    margin-bottom: 0;
    font-size: 20px;
}
.card-footer {
    border-top: 0;
    background-color: inherit;

    a {
        border-radius: 100%;
    }
}

a {
    text-decoration: none;
    color: var(--primary-color);
    border-radius: 4px;

    &:focus, &:hover {
        color: var(--success-color) !important;
        transition: color .2s ease-in-out;
    }


}

.primary-color {
    color: var(--primary-color);
}


.can-claim-egld {
    animation: shadowGrowing 1.2s forwards infinite;
    
    .card-header, img {
        animation: borderColor 1.2s forwards infinite;
    }
    
}

@keyframes shadowGrowing {
    0% {
        box-shadow: 0px 0px 0px 0px var(--primary-color);
        border-color: #4e5052;
    }
    50% {
        box-shadow: 0px 0px 1em var(--primary-color);
        border-color: var(--primary-color);
    }
    100% {
        box-shadow: 0px 0px 0px 0px var(--primary-color);
        border-color: #4e5052;
    }
}

@keyframes borderColor {
    0% {
        border-color: #4e5052;
    }
    50% {
        border-color: var(--primary-color);
    }
    100% {
        border-color: #4e5052;
    }
}

.bold {
    font-weight: 700;
}

.smaller-text {
    font-size: .9em;
}

p {
    margin-bottom: 0;
}
